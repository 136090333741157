// import { qs, qsa, $on, $delegate } from './utils';
import AOS from 'aos';
import '../stylesheets/style.scss';

AOS.init({ disable: 'mobile' });

let xhttp = new XMLHttpRequest(),
  langDocument = {};

document.querySelector('.pl').addEventListener('click', function () {
  switchLanguage(this.dataset.lang);
  document.querySelector('.eng').classList.remove('hide');
  this.classList.add('hide');
});

document.querySelector('.eng').addEventListener('click', function () {
  switchLanguage(this.dataset.lang);
  document.querySelector('.pl').classList.remove('hide');
  this.classList.add('hide');
});

document.querySelector('.pl-sec').addEventListener('click', function () {
  switchLanguage(this.dataset.lang);
  document.querySelector('.eng-sec').classList.remove('hide');
  this.classList.add('hide');
});

document.querySelector('.eng-sec').addEventListener('click', function () {
  switchLanguage(this.dataset.lang);
  document.querySelector('.pl-sec').classList.remove('hide');
  this.classList.add('hide');
});

xhttp.onreadystatechange = function () {
  if (this.readyState === 4 && this.status === 200) {
    langDocument = JSON.parse(this.responseText);
    processLangDocument();
  }
};

function switchLanguage(language) {
  xhttp.open('GET', 'i18n/' + language + '.json', true);
  xhttp.setRequestHeader('Content-type', 'application/json');
  xhttp.send();
}

function processLangDocument() {
  let tags = document.querySelectorAll('span,p,img,a,label,li,option,h1,h2,h3,h4,h5,h6');
  Array.from(tags).forEach(function (value) {
    let key = value.dataset.langkey;
    if (langDocument[key]) value.innerText = langDocument[key];
  });
}
